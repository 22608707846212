<template>
  <a-result status="404" title="404" sub-title="很抱歉，您访问的页面不存在。">
    <template #extra>
      <a-button type="primary">
        <router-link to="/">返回首页</router-link>
      </a-button>
    </template>
  </a-result>
</template>

<script>
  export default {
    name: '404',
    components: {
    },
    data() {
      return {
      }
    },

    methods: {

    },

    created() {

    },

    mounted() {

    }
  }
</script>

<style>

</style>
